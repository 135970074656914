import { Controller } from "stimulus";
import Lottie from "lottie-web";

export default class extends Controller {

  connect() {
    Lottie.loadAnimation({
      container: this.element,
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: this.element.dataset.lottiePath,
      name: this.element.dataset.lottieName || "Lottie Animation",
    });
  }
}
